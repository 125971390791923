<template>
 <b-card>
   <div style="display: flex; justify-content: space-between;">
     <div style="display: flex">
       <svg
           xmlns="http://www.w3.org/2000/svg"
           x="0px"
           y="0px"
           width="35"
           height="35"
           viewBox="0 0 172 172"
           style=" fill:#000000; cursor: pointer"
           @click="$router.go(-1)"
       ><g
           fill="none"
           fill-rule="nonzero"
           stroke="none"
           stroke-width="1"
           stroke-linecap="butt"
           stroke-linejoin="miter"
           stroke-miterlimit="10"
           stroke-dasharray=""
           stroke-dashoffset="0"
           font-family="none"
           font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"
       ><path
           d="M0,172v-172h172v172z"
           fill="none"
       /><g fill="#08071a"><path d="M86,154.8c37.9948,0 68.8,-30.8052 68.8,-68.8c0,-37.9948 -30.8052,-68.8 -68.8,-68.8c-37.9948,0 -68.8,30.8052 -68.8,68.8c0,37.9948 30.8052,68.8 68.8,68.8zM53.27987,81.94653l22.93333,-22.93333c1.118,-1.118 2.58573,-1.67987 4.05347,-1.67987c1.46773,0 2.93547,0.56187 4.05347,1.67987c2.24173,2.24173 2.24173,5.8652 0,8.10693l-13.14653,13.14653h43.49307c3.1648,0 5.73333,2.5628 5.73333,5.73333c0,3.17053 -2.56853,5.73333 -5.73333,5.73333h-43.49307l13.14653,13.14653c2.24173,2.24173 2.24173,5.8652 0,8.10693c-2.24173,2.24173 -5.8652,2.24173 -8.10693,0l-22.93333,-22.93333c-2.24173,-2.24173 -2.24173,-5.8652 0,-8.10693z" /></g></g></svg>

       <div class="titulo-pagina ml-1">
         <div style="align-self: center">Cadastrar Usuário
         </div>
       </div>
     </div>
   </div>
   <div>
     <b-row class="mt-2">
       <b-col>
         <b-form-group

             label="Nome do Usuario:"
             label-for="nome"
         >
           <b-form-input
               id="nome"
               v-model="nomeuser"
               placeholder="Nome do Usuario"
           />
         </b-form-group>
       </b-col>
     </b-row>
     <b-row>
       <b-col md="6">
         <b-form-group

             label="Senha:"
             label-for="senha"
         >
           <b-form-input
               id="senha"
               v-model="senhauser"
               placeholder="Senha..."
               type="password"
           />
         </b-form-group>
       </b-col>
       <b-col md="6">
         <b-form-group

             label="E-Mail:"
             label-for="email"
         >
           <b-form-input
               id="email"
               v-model="emailuser"
               placeholder="E-Mail"
           />
         </b-form-group>
       </b-col>
     </b-row>
     <b-row class="mt-2">
       <b-col>
         <b-button
             class="px-3"
             variant="success"
             @click="SalvarCliente()"
         >
           Realizar Cadastro
         </b-button>

         <b-button
             class="px-3 ml-1"
             variant="outline-danger"
             @click="LimparCampos()"
         >
           Limpar Campos
         </b-button>
       </b-col>
     </b-row>
   </div>
 </b-card>
</template>

<script>

import { BRow, BCol, BFormGroup } from 'bootstrap-vue'

export default {
  name: 'IndexCliente',
  components: {
    BRow,
    BCol,
    BFormGroup,
  },
  data() {
    return {
      url: 'auth/register',
      data: {},
      // ---------- Dados V-Model ----------- \\
      id: null,
      nomeuser: null,
      emailuser: null,
      senhauser: null,
    }
  },

  created() {},
  methods: {
    Notificacao(cor, msg) {
      this.$bvToast.toast(msg, {
        title: 'NOTIFICAÇÃO',
        variant: cor,
        solid: true,
      })
    },
    LimparCampos() {
      this.id = null
      this.nomeuser = null
      this.emailuser = null
      this.senhauser = null
    },
    async SalvarCliente() {
      const obj = {
        name: this.nomeuser,
        email: this.emailuser,
        password: this.senhauser,
      }
      this.$loading(true)
      if (this.id === null) {
        await this.$http.post('auth/register', obj).then(resp => {
          this.Notificacao('success', resp.data.success)
          this.$router.push({
            name: 'adm-usuario',
          })
        })
        this.$loading(false)
      }
    },
  },
}
</script>

<style scoped>

</style>
